<template>
  <div class="impot-revenu-create page-layout">
    <PageHeader title="Créer un impôt revenu" :has-back="true" @back="goBack" />

    <md-card class="meep-form">
      <md-card-content>
        <div class="meep-input">
          <div class="meep-form-single-column">
            <md-field :class="{ 'md-invalid': errors.has('montant') }">
              <label>Montant *</label>
              <md-input
                v-model="form.montant"
                v-validate="'required|decimal'"
                name="montant"
                type="text"
              >
              </md-input>

              <span class="md-error" v-show="errors.has('montant')">
                {{ errors.first("montant") }}
              </span>
            </md-field>
          </div>

          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('coef') }">
              <label>Coefficiant *</label>
              <md-input
                v-model="form.coef"
                v-validate="'required|decimal'"
                name="coef"
                type="text"
              >
              </md-input>

              <span class="md-error" v-show="errors.has('coef')">
                {{ errors.first("coef") }}
              </span>
            </md-field>
          </div>

          <div class="md-layout md-alignment-center-right">
            <md-button
              class="md-raised md-primary"
              @click.native="createRevenuDeImpot"
            >
              Créer un impôt revenu
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import calculatorsModel from "../../../../model/calculators";
import PageHeader from "@/components/PageHeader";

export default {
  components: { PageHeader },
  data() {
    return {
      isDebug: false,
      form: {
        montant: "",
        coef: "",
      },
    };
  },
  methods: {
    goBack() {
      window.history.back();
    },
    createRevenuDeImpot() {
      this.$validator.validateAll().then(result => {
        if (result) {
          calculatorsModel
            .createRevenu(this.form)
            .then(() => {
              this.$toasted.global.AppSucces({
                message: "L'impot de revenu a bien été crée",
              });

              this.$router.push("/admin/calculators/impot-revenu/list");
            })
            .catch(err => {
              this.$toasted.global.AppError({
                message: err.msg,
              });
            });
        } else {
          this.$toasted.global.AppInfo({
            message: "Vous devez remplir les champs manquants",
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.impot-revenu-create {
  .debug-log {
    max-height: 360px;
    overflow-y: scroll;
  }

  .md-card {
    margin-top: 32px;
    margin-bottom: 32px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
